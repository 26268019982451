













































































































































































































































































import Vue from 'vue';
import { VetSkillType } from '@/models/position.model';
import { Practice } from '@/models/practice.model';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import { db, storage } from '@/main';
import { Place } from '@/models/place.model';
import { Position } from '@/models/position.model';
import { Request, RequestStatus } from '@/models/request.model';
import { TeamMember } from '@/models/teamMember.model';

export default Vue.extend({
  name: 'PositionView',
  components: {
    SalaryValue: () => import('@/components/SalaryValue.vue'),
    TeamMemberCard: () => import('@/components/TeamMemberCard.vue')
  },
  data() {
    return {
      practice: {} as Practice,
      position: {} as Position,
      practiceLogoUrl: String,
      practiceImageUrls: [] as string[],
      teamMembers: [] as TeamMember[],
      teamMemberImageUrls: {},
      place: {} as Place,
      placeLoading: false,
      distance: String,
      duration: String,
      distanceRetrieved: false,
      candidateVetSkillTypes: this.$store.state.currentUser.vetSkillTypes
    };
  },
  methods: {
    checkIfFunction(value: any): boolean {
      return typeof value === 'function';
    },
    getData() {
      db.collection('positions')
        .doc(this.$route.query.positionUid as string)
        .get()
        .then(async (doc: firebase.firestore.DocumentData) => {
          this.position = doc.data() as Position;
        });
      this.getPractice();
    },
    getPractice() {
      db.collection('practices')
        .doc(this.$route.query.practiceUid as string)
        .get()
        .then(async (doc: firebase.firestore.DocumentData) => {
          this.practice = doc.data() as Practice;
          this.getDistance(this.practice.googlePlaceId);
          this.getTeamMembers();
          this.practiceImageUrls = await Promise.all(
            this.practice.insidePracticeImageRefs.map(async ref =>
              storage.ref(ref).getDownloadURL()
            )
          );
          this.practiceLogoUrl = await Promise.resolve(
            storage.ref(this.practice.logoImageRef).getDownloadURL()
          );
        });
    },
    getTeamMembers() {
      db.collection('practices')
        .doc(this.practice.uid)
        .collection('teamMembers')
        .get()
        .then(snapshot => {
          this.teamMembers = snapshot.docs.map(doc => doc.data() as TeamMember);
          this.teamMembers.forEach(async teamMember => {
            Vue.set(
              this.teamMemberImageUrls,
              teamMember.uid,
              await storage.ref(teamMember.imageRef).getDownloadURL()
            );
          });
        });
    },
    getDistance(practicePlaceId: string) {
      return firebase
        .functions()
        .httpsCallable('getDistanceBetweenPlaces')({
          origin: this.$store.state.currentUser.googlePlaceId,
          destination: practicePlaceId
        })
        .then(result => {
          this.distance = result.data.distance.text;
          this.duration = result.data.duration.text;
          this.distanceRetrieved = true;
        })
        .catch(error => {
          console.error(error);
        });
    },
    getSkillsMatchPercentage(skillsRequired: VetSkillType[]): string {
      if (skillsRequired && this.$store.state.currentUser.vetSkillTypes) {
        const intersection = skillsRequired.filter(element =>
          this.$store.state.currentUser.vetSkillTypes.includes(element)
        );
        return ((intersection.length / skillsRequired.length) * 100).toFixed(0);
      }
      return '0';
    },
    getId(): string {
      const url =
        this.practice.youtubeProfileUrl?.split(
          /(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/
        ) ?? '';
      return url[2] !== undefined ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0];
    },
    requestInterview() {
      const request = {
        uid: db.collection('requests').doc().id,
        date: firebase.firestore.Timestamp.now(),
        internalReference: this.position.internalReference,
        candidateUid: this.$store.state.currentUser.uid,
        practiceUid: this.practice.uid,
        positionUid: this.position.uid,
        status: RequestStatus.open
      } as Request;
      db.collection('requests')
        .doc(request.uid)
        .set(request)
        .then(() => {
          this.$router.push({
            name: 'RequestView',
            query: {
              requestUid: request.uid,
              candidateUid: request.candidateUid,
              positionUid: request.positionUid,
              practiceUid: request.practiceUid
            }
          });
        })
        .catch((error: Error) => {
          console.error(error);
        });
    }
  },
  created() {
    this.getData();
  }
});
