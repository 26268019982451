import { render, staticRenderFns } from "./PositionView.vue?vue&type=template&id=20528127&scoped=true&"
import script from "./PositionView.vue?vue&type=script&lang=ts&"
export * from "./PositionView.vue?vue&type=script&lang=ts&"
import style0 from "./PositionView.vue?vue&type=style&index=0&lang=css&"
import style1 from "./PositionView.vue?vue&type=style&index=1&id=20528127&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20528127",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VBtn,VCard,VCardText,VCol,VContainer,VIcon,VImg,VRow,VSimpleTable,VSkeletonLoader,VSpacer})
